<template>
<div>
<base-dialog :show="isUploading" title="Uploading Files..." fixed>
<base-spinner>Pleas wait to finish.</base-spinner>
</base-dialog>
    <section class="section section-sm">
      <div class="container">
        <br />

<h2>{{ appMessages.createPage.[lgs] }}</h2>

        <div v-show="formSubmited">
          <p v-show="apiError.error">
            <img
              alt="Subotica"
              src="../../assets/error.svg"
              width="35"
              hspace="4"
              style="vertical-align: middle"
            />
            {{ apiError.message }}
          </p>
        </div>

        <!-- FORM -->
        <form v-show="!formSubmited" @submit.prevent="submitForm">

<!--PAGE DASHBOARD -->
 <div class="dashboard-container">
 <base-button class="ml-3">{{ appMessages.save.[lg] }}</base-button>
 <base-button class="back-go-dasboard" type="button" @click="goToDashboard">{{ appMessages.dashboard.[lg] }}</base-button>
 </div>
<!--END PAGE DASHBOARD -->

        <!-- page category -->

<!--PAGE CATEGORY -->
    <label for="pageCategory">{{appMessages.category.[lg]}}</label>
    <div class="current-page-category">{{ pageCategoryPath.path }}</div>
    <ul id="page-category-tree" class="page-category-tree">
      <tree-item :item=treeDataDB ></tree-item>
	</ul>

          <div class="form-control" :class="{ invalid: !pageCategoryIds.isValid }">
            <p v-if="!pageCategoryIds.isValid">
              <img alt="Error" src="../../assets/error.svg" width="35" hspace="4" style="vertical-align: middle" />
				  Please select page category!
            </p>
            </div>
<!-- END PAGE CATEGORY -->

<!-- PAGE DATE -->
<div class="form-control" :class="{ invalid: !pageDate.isValid }">
<div class="flex-container dpc">
 <label for="pageDate">{{appMessages.pageDate.[lg]}}: </label>
 <input  type="text" id="pageDate" placeholder="2021-09-30" v-model.trim="pageDate.val" @blur="clearValidity('pageDate')" class="date-picker"/>
</div>
<p v-if="!pageDate.isValid">
 <img alt="Error" src="../../assets/error.svg" width="35" hspace="4" style="vertical-align: middle" />
  Page Date must not be empty.
</p>
</div>
<!-- END PAGE DATE -->

<!--FLAG BAR -->
<div class="flex-lg-container">
<div class="flex-lg-item" v-bind:class="{ ooo: flagAdmin.cp }">
<img  @click="activateLg('cp')" src="/assets/images/icons/flags/cp.svg" alt="Srpski" title="Srpski" height="30" width="50"/>
</div>

<div class="flex-lg-item"  v-bind:class="{ ooo: flagAdmin.sk }">
<img @click="activateLg('sk')" src="/assets/images/icons/flags/sk.svg" alt="Slovenský" title="Slovenský" height="30" width="50"/>
</div>

<div class="flex-lg-item"  v-bind:class="{ ooo: flagAdmin.ro }">
<img  @click="activateLg('ro')" src="/assets/images/icons/flags/ro.svg" alt="Română" title="Română" height="30" width="50"/>
</div>

<div class="flex-lg-item"  v-bind:class="{ ooo: flagAdmin.hu }">
<img  @click="activateLg('hu')" src="/assets/images/icons/flags/hu.svg" alt="Magyar" title="Magyar" height="30" width="50"/>
</div>

<div class="flex-lg-item"  v-bind:class="{ ooo: flagAdmin.en }">
<img  @click="activateLg('en')" src="/assets/images/icons/flags/en.svg" alt="English" title="English" height="30" width="50"/>
</div>
</div>
<!--END FLAG BAR -->

<!--TITLE & CONTENT -->
<div class="lg-box">
<!--TITLE LG -->
<div  v-if="flagAdmin.cp" class="flex-container">
          <div class="form-control" :class="{ invalid: !titleCP.isValid }">
            <label for="firstname">{{appMessages.pageTitle.[lg]}}:</label>
            <input
              type="text"
              id="firstname"
              placeholder="Title..."
              v-model.trim="titleCP.val"
              @blur="clearValidity('titleCP')"
            />
            <p v-if="!titleCP.isValid">
              <img alt="Error" src="../../assets/error.svg" width="35" hspace="4" style="vertical-align: middle" />
				  Title CP must not be empty.
            </p>
          </div>
</div>

<div  v-if="flagAdmin.sk" class="flex-container">
          <div class="form-control" :class="{ invalid: !titleSK.isValid }">
            <label for="firstname">{{appMessages.pageTitle.[lg]}}:</label>
            <input
              type="text"
              id="firstname"
              placeholder="Title..."
              v-model.trim="titleSK.val"
              @blur="clearValidity('titleSK')"
            />
            <p v-if="!titleSK.isValid">
              <img alt="Error" src="../../assets/error.svg" width="35" hspace="4" style="vertical-align: middle" />
				  Title SK must not be empty.
            </p>
          </div>
</div>

<div  v-if="flagAdmin.ro" class="flex-container">
          <div class="form-control" :class="{ invalid: !titleRO.isValid }">
            <label for="firstname">{{appMessages.pageTitle.[lg]}}:</label>
            <input
              type="text"
              id="firstname"
              placeholder="Title..."
              v-model.trim="titleRO.val"
              @blur="clearValidity('titleRO')"
            />
            <p v-if="!titleRO.isValid">
              <img alt="Error" src="../../assets/error.svg" width="35" hspace="4" style="vertical-align: middle" />
				  Title RO must not be empty.
            </p>
          </div>
</div>

<div  v-if="flagAdmin.hu" class="flex-container">
          <div class="form-control" :class="{ invalid: !titleHU.isValid }">
            <label for="firstname">{{appMessages.pageTitle.[lg]}}:</label>
            <input
              type="text"
              id="firstname"
              placeholder="Title..."
              v-model.trim="titleHU.val"
              @blur="clearValidity('titleHU')"
            />
            <p v-if="!titleHU.isValid">
              <img alt="Error" src="../../assets/error.svg" width="35" hspace="4" style="vertical-align: middle" />
				  Title HU must not be empty.
            </p>
          </div>
</div>

<div  v-if="flagAdmin.en" class="flex-container">
          <div class="form-control" :class="{ invalid: !titleEN.isValid }">
            <label for="firstname">{{appMessages.pageTitle.[lg]}}:</label>
            <input
              type="text"
              id="firstname"
              placeholder="Title..."
              v-model.trim="titleEN.val"
              @blur="clearValidity('titleEN')"
            />
            <p v-if="!titleEN.isValid">
              <img alt="Error" src="../../assets/error.svg" width="35" hspace="4" style="vertical-align: middle" />
				  Title EN must not be empty.
            </p>
          </div>
</div>

<!-- /TITLE LG -->
<!-- CONTENT LG -->
<div  v-if="flagAdmin.cp" class="flex-container">
          <div class="form-control" :class="{ invalid: !contentCP.isValid }">
            <label for="contentCP">{{appMessages.pageContent.[lg]}}</label>
				<editor v-bind:api-key=apiKey 
				:init=initTinyMCE 
				v-model.trim="contentCP.val" 
				@blur="clearValidity('contentCP')"
				/>
            <p v-if="!contentCP.isValid">
              <img
                alt="Subotica"
                src="../../assets/error.svg"
                width="35"
                hspace="4"
                style="vertical-align: middle"
              />ContentCP must not be empty.
            </p>
          </div>
</div>

<div  v-if="flagAdmin.sk" class="flex-container">
          <div class="form-control" :class="{ invalid: !contentSK.isValid }">
            <label for="contentSK">{{appMessages.pageContent.[lg]}}</label>
				<editor v-bind:api-key=apiKey 
				:init=initTinyMCE 
				v-model.trim="contentSK.val" 
				@blur="clearValidity('contentSK')"
				/>
            <p v-if="!contentSK.isValid">
              <img
                alt="Subotica"
                src="../../assets/error.svg"
                width="35"
                hspace="4"
                style="vertical-align: middle"
              />ContentSK must not be empty.
            </p>
          </div>
</div>

<div  v-if="flagAdmin.ro" class="flex-container">
          <div class="form-control" :class="{ invalid: !contentRO.isValid }">
            <label for="contentRO">{{appMessages.pageContent.[lg]}}</label>
				<editor v-bind:api-key=apiKey 
				:init=initTinyMCE 
				v-model.trim="contentRO.val" 
				@blur="clearValidity('contentRO')"
				/>
            <p v-if="!contentRO.isValid">
              <img
                alt="Subotica"
                src="../../assets/error.svg"
                width="35"
                hspace="4"
                style="vertical-align: middle"
              />ContentRO must not be empty.
            </p>
          </div>
</div>

<div  v-if="flagAdmin.hu" class="flex-container">
          <div class="form-control" :class="{ invalid: !contentHU.isValid }">
            <label for="contentHU">{{appMessages.pageContent.[lg]}}</label>
				<editor v-bind:api-key=apiKey 
				:init=initTinyMCE 
				v-model.trim="contentHU.val" 
				@blur="clearValidity('contentHU')"
				/>
            <p v-if="!contentHU.isValid">
              <img
                alt="Subotica"
                src="../../assets/error.svg"
                width="35"
                hspace="4"
                style="vertical-align: middle"
              />ContentHU must not be empty.
            </p>
          </div>
</div>

<div  v-if="flagAdmin.en" class="flex-container">
          <div class="form-control" :class="{ invalid: !contentEN.isValid }">
            <label for="content">{{appMessages.pageContent.[lg]}}</label>
				<editor v-bind:api-key=apiKey 
				:init=initTinyMCE 
				v-model.trim="contentEN.val" 
				@blur="clearValidity('contentEN')"
				/>
            <p v-if="!contentEN.isValid">
              <img
                alt="Subotica"
                src="../../assets/error.svg"
                width="35"
                hspace="4"
                style="vertical-align: middle"
              />ContentEN must not be empty.
            </p>
          </div>
</div>

<!-- /CONTENT LG -->
</div>
<!--END TITLE & CONTENT -->

<!--PAGE ORDER -->
<div class="flex-container">
 <div class="form-control" :class="{ invalid: !pageOrder.isValid }">
            <label for="pageOrder">{{ appMessages.pageOrder.[lg] }}:</label>
            <input
							type="number"
              id="pageOrder"
              v-model.number="pageOrder.val"
              @blur="clearValidity('pageOrder')"
            />
            <p v-if="!pageOrder.isValid">
              <img
                alt="Subotica"
                src="../../assets/error.svg"
                width="35"
                hspace="4"
                style="vertical-align: middle"
              />Number of pageOrder must be greater than 0.
            </p>
          </div>
</div>
<!-- END PAGE ORDER -->

<!--FRONTPAGE & ACTIVE -->
<div class="flex-container">
        <div class="form-control">
          <label for="active">{{ appMessages.frontPage.[lg] }}:</label>
			CP <input type="checkbox" id="checkbox" v-model="frontPageCP.val">
			SK <input type="checkbox" id="checkbox" v-model="frontPageSK.val">
			RO <input type="checkbox" id="checkbox" v-model="frontPageRO.val">
			HU <input type="checkbox" id="checkbox" v-model="frontPageHU.val">
			EN <input type="checkbox" id="checkbox" v-model="frontPageEN.val">
        </div>
        <div class="form-control">

          <label for="active">{{ appMessages.active.[lg] }}:</label>
			CP <input type="checkbox" id="checkbox" v-model="activeCP.val">
			SK <input type="checkbox" id="checkbox" v-model="activeSK.val">
			RO <input type="checkbox" id="checkbox" v-model="activeRO.val">
			HU <input type="checkbox" id="checkbox" v-model="activeHU.val">
			EN <input type="checkbox" id="checkbox" v-model="activeEN.val">
        </div>
</div>
<!-- END FRONTPAGE & ACTIVE -->

          <!-- / FORM -->

            <div class="error-container" role="alert" v-if="detectionErrors?.length > 0">
              <h4 class="error mb-2">Please upload a picture of the items below</h4>
              <ul>
                <li v-for="error in detectionErrors" :key="error"><strong class="error">{{error.key}}: {{error.missing}} missing</strong></li>
              </ul>
            </div>

          <p class="error-container error mb-3" v-if="!formIsValid">Please fix the above errors and submit again.</p>

<!--  FILE UPLOAD BOX-->
<div class="file-upload">
          <div v-show="showFileUpload">
            <h2>{{ appMessages.fileUpload.[lg] }}</h2>
						<span v-html="appMessages.fileType.[lg]"></span>
            <ul>
              <li v-for="item in selectedFilesDescriptions" :key="item">
                File name: {{ item.name }}, size: {{ item.size }} Bytes
              </li>
            </ul>
            <div class="d-flex justify-content-center mt-3">
              <input
                style="display: none"
                type="file"
                @change="onFileSelected"
                ref="fileInput"
                multiple
              />
              <base-button
                class="upload_files"
                type="button"
                @click="$refs.fileInput.click()"
                >{{ appMessages.selectFilesToUpload.[lg] }}</base-button
              >
            </div>

            </div>

</div>
<!--  FILE UPLOAD BOX-->
<!--PAGE DASHBOARD -->
 <div class="dashboard-container">
 <base-button class="ml-3">{{ appMessages.save.[lg] }}</base-button>
 <base-button type="button" @click="goToDashboard">{{ appMessages.dashboard.[lg] }}</base-button>
 </div>
<!--END PAGE DASHBOARD -->
        </form>
      </div>
      <img style="display: none" id="imgtodetect" src="" alt="" />
    </section>
  </div>
</template>

<script>
import api from "../../../api/client";
import {appMessages} from "../../translations/messages";
import TreeItem from './TreeItem.vue';
import Editor from '@tinymce/tinymce-vue'
import { convertDateToLg, cyrToLat } from "../../helpers/index.js";

let images;
let net;
let reader;

export default {
  components: {
    TreeItem,
    'editor': Editor
  },
  data() {
    return {
		appMessages:appMessages,
		lg:"sr",
		saved: false,
		pageTitle: "Create new page",
      filePayload: null,
      pageCategoryIds: { val: 0, isValid: true },
      pageDate: { val: "", isValid: true },
      titleCP: { val: "", isValid: true },
      titleSR: { val: "", isValid: true },
      titleSK: { val: "", isValid: true },
      titleRO: { val: "", isValid: true },
      titleHU: { val: "", isValid: true },
      titleEN: { val: "", isValid: true },
      contentCP: { val: "", isValid: true },
      contentSR: { val: "", isValid: true },
      contentSK: { val: "", isValid: true },
      contentRO: { val: "", isValid: true },
      contentHU: { val: "", isValid: true },
      contentEN: { val: "", isValid: true },
      pageOrder: { val: 4, isValid: true },
      frontPageCP: { val: false, isValid: true },
      frontPageSR: { val: false, isValid: true },
      frontPageSK: { val: false, isValid: true },
      frontPageRO: { val: false, isValid: true },
      frontPageHU: { val: false, isValid: true },
      frontPageEN: { val: false, isValid: true },
      activeCP: { val: true, isValid: true },
      activeSR: { val: true, isValid: true },
      activeSK: { val: true, isValid: true },
      activeRO: { val: true, isValid: true },
      activeHU: { val: true, isValid: true },
      activeEN: { val: true, isValid: true },
		pageCategoryPath:"",
      selectedFile: null,
      selectedFiles: [],
      selectedFilesDescriptions: [],
      formIsValid: true,
      formSubmited: false,
      isLoading: false,
      showSuccess: false,
			isUploading: false,
      showFileUpload: true,
      error: this.$store.getters["apiError"],
      errorMessage: "",
      detectionErrors: [],
      isModelDetecting: false,
      apiUrl: '',
		apiResponseData:'',
		apiResponseFile:'',
		idCreated: null,
      items: [],
		loading: false, 
      tree: "",
		flagAdmin: {cp: true, sk: false, ro: false, hu: false, en: false },
		isOoo:true,
		treeDataDB: { title: "Please Select Page Category ", id:0, children:[] },
      apiKey: "q2o2ca8zadwqsxhaze5jcm0efhrxvthyg7jibisu7nd6zag6",
      initTinyMCE: {
 	     menubar: false,
 	     plugins: 'lists link image emoticons code fullscreen',
 	     toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons code fullscreen',
       image_list: [
		  ]
        }
    };
  },
  computed: {
    apiError() {
      return this.$store.getters["page/apiError"];
    },
    itemFromDatabase() {
      return this.$store.getters["page/itemFromDatabase"];
    },
		 lgs () {
			this.lg=this.$store.getters["lg"]
	  	return this.$store.getters["lg"]
		 }
  },
  mounted() {
   this.$store.commit("page/setShowCarusel",false);
  },
  created() {
    this.loadPageCategories()
    this.apiUrl = process.env.VUE_APP_API_URL;
  },
  methods: {

	 //Page Category Tree
	  async loadPageCategories () {
			this.$store.commit('page/deletePageCategoryIds');
		  this.loading = true
		  this.items = await api.getPageCategories()
		  this.loading = false

			this.tree=this.items;
			this.convertToJSONTree(this.items)
			this.treeDataDB.children=this.tree;

	  },
	  convertToJSONTree  (data)  {
		  const tree = data.map(e => ({...e}))
			  .sort((a, b) => a.id - b.id)
			  .reduce((a, e) => {
					  a[e.id] = a[e.id] || e;
					  a[e.parentId] = a[e.parentId] || {};
					  const parent = a[e.parentId];
					  parent.children = parent.children || [];
					  parent.children.push(e);
					  return a;
					  }, {})
		  ;
		  this.tree= Object.values(tree) .find(e => e.id === undefined).children;
	  },
	 //Page Category Tree

    async storeFiles(event) {
      let err;
      const imageTypes = ["image/jpeg", "image/jpg", "image/png"];
      event.target.files.forEach((file) => {
        if (!imageTypes.includes(file.type)) err = "Extensions is not enabled";
      });
      if (err) {
        console.log(err);
        return;
      }
      images = event.target.files;
    },
    goToMyPages() {
      this.$router.replace("listmypage");
    },
    goToShowPage() {
      this.$router.replace(`/showpage/${this.idCreated}`);
    },
    goToUpdatePage() {
      this.$router.replace(`/updatepage/${this.idCreated}`);
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
		 console.log("itt vagyok validation");
		 console.log("xxx");
		 console.log(this.pageCategoryIds);
		 console.log("xxx");

      this.formIsValid = true;

		this.pageCategoryIds.val=this.$store.getters['page/pageCategoryIds']
      if (this.pageCategoryIds.val > 0) { this.pageCategoryIds.isValid = true }
      if (this.pageCategoryIds.val < 1) {
        this.pageCategoryIds.isValid = false;
        this.formIsValid = false;
      }
      if (this.pageDate.val === "") {
        this.pageDate.isValid = false;
        this.formIsValid = false;
      }
      if (this.titleCP.val === "") {
        this.titleCP.isValid = false;
        this.formIsValid = false;
      }
      if (this.contentCP.val === "") {
        this.contentCP.isValid = false;
        this.formIsValid = false;
      }
      if (!this.pageOrder.val || this.pageOrder.val < 0) {
        this.pageOrder.isValid = false;
        this.formIsValid = false;
      }
    },

    async submitForm () {
      this.detectionErrors = []
      this.validateForm();

		const pageCategoryIds=this.$store.getters['page/pageCategoryIds']
      if (!this.formIsValid) {return;} 
		else {this.formSubmited = true;}

      
      const dataPayload = {
        pageCategoryIds: pageCategoryIds,
        pageDate: this.pageDate.val,
        titleCP: this.titleCP.val,
        titleSR: cyrToLat(this.titleCP.val),
        titleSK: this.titleSK.val,
        titleRO: this.titleRO.val,
        titleHU: this.titleHU.val,
        titleEN: this.titleEN.val,
        contentCP: this.contentCP.val,
        contentSR: cyrToLat(this.contentCP.val),
        contentSK: this.contentSK.val,
        contentRO: this.contentRO.val,
        contentHU: this.contentHU.val,
        contentEN: this.contentEN.val,
        pageOrder: this.pageOrder.val,
        frontPageCP: this.frontPageCP.val,
        frontPageSR: this.frontPageCP.val,
        frontPageSK: this.frontPageSK.val,
        frontPageRO: this.frontPageRO.val,
        frontPageHU: this.frontPageHU.val,
        frontPageEN: this.frontPageEN.val,
        activeCP: this.activeCP.val,
        activeSR: this.activeCP.val,
        activeSK: this.activeSK.val,
        activeRO: this.activeRO.val,
        activeHU: this.activeHU.val,
        activeEN: this.activeEN.val,
      };
      //const filePayload = this.filePayload;
      //const payload = [dataPayload, filePayload];
		//console.log("dataPayload"); console.log(payload); console.log("dataPayload");

      this.apiResponseData = await api.createPage(dataPayload)
		//console.log("XXXX this.apiResponse"); console.log(this.apiResponseData); console.log("this.apiResponse");

		//FILE UPLOAD

		this.idCreated=this.apiResponseData.id;
		if (this.selectedFiles.length > 0) {

			this.isUploading=true
      this.apiResponseFile = await api.uploadFiles(this.filePayload, this.idCreated)
			this.isUploading=false
		}
		//FILE UPLOAD
  	  	this.goToShowPage(); 
		//Upload Files





      this.isLoading = false;
    },

    handleError() {
      this.error = null;
    },

    //File upload
	 async testUpload () {
		 console.log("Test Upload");
      //this.filePayload.append('pageId', 320345);
		console.log("filePayload"); console.log(this.filePayload); console.log("filePayload");
      //this.apiResponseFile = await api.uploadFiles(filePayload);
	 },

    async onFileSelected(event) {
      this.storeFiles(event)
      this.showSuccess = false;
      this.selectedFiles = event.target.files;
      this.selectedFile = event.target.files[0];
      var selectedFilesDesc = [];
      this.selectedFiles.forEach((item) => {
        selectedFilesDesc.push({ name: item.name, size: item.size });
      });
      this.selectedFilesDescriptions = selectedFilesDesc;

      var formData = new FormData();
      //formData.append('pageId', this.idCreated);
      //formData.append('pageId', 320374);
		console.log("this.selectedFiles");
		console.log(this.selectedFiles);
		console.log("this.selectedFiles");

      this.selectedFiles.forEach((item) => {
        formData.append("pageFile", item, item.name);
		  console.log("x");
      });

      //formData.append('pageId', '320345');
		//console.log("formData");console.log(formData);console.log("formData"); 
		this.filePayload=formData;
		/*

		console.log("ddd");console.log(this.filePayload);console.log("ddd"); 
		for(var pair of formData.entries()) {
		   console.log(pair[0]+ ', '+ pair[1]);
		   console.log(pair[1]);
		}
		*/

		//console.log("filePayload"); console.log(this.filePayload); console.log("filePayload");

      //this.apiResponseFile = await api.uploadFiles(formData);
    },
		activateLg(lg) {
			 console.log(lg);
			 this.flagAdmin[lg]=true
				for(var key in this.flagAdmin) {
				 if (lg==key) { this.flagAdmin[key]=true;}
				else { this.flagAdmin[key]=false;}
				}
				this.isOoo=false;

		},
    goToDashboard() {
      this.$router.replace("/dashboard");
    },
  }// end of methods,

};
</script>

<style scoped>
.flex-container {
	display:flex;
	justify-content: center;
}
.form-control {
  margin: 0.5rem 0;
  border: none;
}
label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type="checkbox"] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}
input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}
input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}
input[type="checkbox"]:focus {
  outline: #3d008d solid 1px;
}
h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}
.invalid label {
  color: red;
}
.invalid input,
.invalid textarea {
  border: 1px solid red;
}
.from_database {
  margin: 1rem 0;
  border: 2px solid #cee5f5;
  border-radius: 5px;
  padding: 1rem;
  background-color: #e8f4fc;
}
.success {
  text-align: center;
  margin: 1rem 0;
  border: 2px solid #cee5f5;
  border-radius: 5px;
  padding: 1rem;
  background-color: #e8f4fc;
}
.img-container-block {
  text-align: center;
}
.img-container-inline {
  text-align: center;
  display: block;
}
.item_image {
  max-width: 100%;
  max-height: 100%;
}
span {
  display: table-cell;
  padding: 0 4px 0 6px;
}
/*inline form*/

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline label {
  margin: 20px 10px 5px 0;
  font-weight: normal;
}

.form-inline input {
  vertical-align: middle;
  margin: 2px 1px 1px 0;
  padding: 2px;
  background-color: #fff;
  width: 90px;
  border: 1px solid #ddd;
}
@media (max-width: 800px) {
  .form-inline input {
    margin: 10px 0;
  }
  .form-inline {
    flex-direction: column;
    align-items: stretch;
  }
}
.centered {
  text-align: center;
  display: block;
  margin: 10px 0 10px 0;
}
.upload_files {
  background-color: #25809c;
}
.red_button {
  background-color: #cc0000;
  width: 100px;
  padding: 3px;
}

.red_button_placeholder {
  width: 110px;
}
.am_name {
  width: 140px;
}
.add_am {
  padding-top: 10px;
}
.ai {
  background-color: #ff832b;
  padding: 10px;
  border: 1px solid #ddd;
  border: #ab5519 solid 1px;
  border-radius: 5px;
  color: white;
  font-size: 25px;
  text-align: center;
}
.error {
  color: #a94442;
}
.error-container {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.custom-progress {
  background-color:#ff830a;
  width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.page-category-tree {
    background-color: #d8f0f2;
    border-color: #ebccd1;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.activePageCategory {
  font-weight: bold;
  color: #FF0000;
}
.passivePageCategory {
  font-weight: normal;
  color: #000000;
}

.flex-lg-container {
	 background-color:#ccc;
	 display:flex;
	 margin-top:10px; 
}
.flex-lg-item {
	 cursor:pointer;
	 padding-right:10px; 
	 opacity: 0.25;
}
.lg-box {

    border: 2px solid #ccc;
}
.ooo{
	 opacity: 1.0;
}
.file-upload {
	 background-color:#f7f7f7;
   border: 1px solid #aaa;;
   border-radius: 4px;
	 padding:1rem;
}
.dashboard-container {
	display:flex;
	justify-content: center;
	margin-bottom: 0.4rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.back-go-dasboard {
	 background-color:#880000;
}
input[type='number']{
    width: 80px;
} 
input[type="checkbox"] {
    cursor: pointer;
}
input[type='text']{
		margin-left:10px;
} 
.dpc {
  justify-content: left;
    width: 300px;
}

</style>
